<template>
<div ui-view autoscroll="true" style="min-height: 100%; " class="ng-scope">
    <div class='content-box ng-scope' style="background: #09b585; background-size: 100% 100%; padding: 0px !important; height: 100%">
        <div id="content" class="menu-side ng-scope company">
            <div class="company__header" style="position: absolute">
                <!-- Button back -->
                <div v-if="currentStep > 1 && currentStep != 6" @click="backStepResgitry()" class="signup-goback-container ng-scope">
                    <i class="icon-feather-arrow-left"></i>
                </div>
                <!-- Button Close -->
                <div class="btnClose" @click="gotoLogin()">
                    X
                </div>
            </div>
            <div class="company__body" style="margin: 30px 0px">
                <div class="" style="margin: auto; max-width: 700px">
                    <!-- Step 1 -->
                    <div v-show="currentStep == 1">
                        <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                            <div class="onboarding-slide" ng-class="{'hide' : currentIndex != 1 }" style="">
                                <div class="onboarding-side-by-side">
                                    <div class="headerimg">
                                        <img class="img" alt="" src="/assets/img/img_step0.png">
                                    </div>
                                    <div class="onboarding-content with-gradient" style="width: 100%;">
                                        <p class="title">
                                            Personal Details
                                        </p>
                                        <div class="onboarding-text">
                                            <!--    Add your employee’s personal details so you have a way to contact them if needed. -->
                                        </div>
                                        <form>
                                            <div class="text-left">
                                                <h5>Address</h5>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="form-group">
                                                            <label for="">Address <span class="text-danger">*</span></label>
                                                            <vue-google-autocomplete v-on:placechanged="getAddressData" v-model="address" v-bind:class="(isCheckFindMyAddress)?'disabled-address': ''" country="au" class="form-control ng-address" name="address" id="address" placeholder="Address" type="text" autocomplete="of"></vue-google-autocomplete>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-check" style="margin-bottom: 10px;">
                                                    <input v-model="isCheckFindMyAddress" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="isCheckFindMyAddress">
                                                    <label class="form-check-label" for="isCheckFindMyAddress">
                                                        I am unable to find my address
                                                    </label>
                                                </div>
                                                <div v-show="isCheckFindMyAddress">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="form-group">
                                                                <label for="">Address 1 <span class="text-danger">*</span></label>
                                                                <input types="address" id="address1" v-model="edtAddress1" name="address1" type="text" class="form-control ng-address1">
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="form-group">
                                                                <label for="">Address 2</label>
                                                                <vue-google-autocomplete v-model="edtAddress2" class="form-control " name="address2" id="address-2" type="text" placeholder="Address 2" autocomplete="on"></vue-google-autocomplete>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="form-group">
                                                                <label for="">Suburb <span class="text-danger">*</span></label>
                                                                <input v-model="edtSuburb" class="form-control ng-suburb" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" name="suburb" type="text" style="">
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="form-group">
                                                                <label for="">Postcode <span class="text-danger">*</span></label>
                                                                <input v-model="edtPostcode" class="form-control ng-postcode" name="postcode" type="number" style="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="form-group">
                                                                <label> State <span class="text-danger">*</span></label>
                                                                <select v-model="edtState" @change="onChange($event)" class="form-control ng-state" name="state" style="">
                                                                    <option value=""></option>
                                                                    <option value="ACT">ACT</option>
                                                                    <option value="NSW">NSW</option>
                                                                    <option value="NT">NT</option>
                                                                    <option value="QLD">QLD</option>
                                                                    <option value="SA">SA</option>
                                                                    <option value="TAS">TAS</option>
                                                                    <option value="VIC">VIC</option>
                                                                    <option value="WA">WA</option>
                                                                    <option value="OTHER">Other</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="form-group">
                                                                <label for=""> Country <span class="text-danger">*</span></label>
                                                                <country-flag :country='countryCode' size='normal' class="flag-input" />
                                                                <el-autocomplete @focus="clickAutocomplete()" @blur="getBackAutocompete()" v-model="country" class="inline-input form-control" style="width: 100%; !important;" :fetch-suggestions="querySearch" placeholder="Please Input" @select="changeLanguage">
                                                                    <template slot-scope="{ item }">
                                                                        <div style="display: flex; width: max-content;">
                                                                            <country-flag :country='item.value' size='normal' />
                                                                            <div class="value">{{ item.text }}</div>
                                                                        </div>
                                                                    </template>
                                                                </el-autocomplete>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="form-group">
                                                            <label for="">Contact number <span class="text-danger">*</span></label>
                                                            <input v-model="edtcontactNumber" @input="formatPhoneNumber" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-phone" placeholder="" type="phone" name="phone" ng-model="employeeDetails_new.ph">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- </slick> -->
                            <div class="row" style="padding:12px">
                                <div class="col-6 text-left"></div>
                                <div class="col-6 text-right">
                                    <!-- ngIf: currentIndex == 0 -->
                                    <button class="btn btn-primary ng-binding" v-on:click="openModalStep2()" ng-disabled="loading">
                                        <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span>
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Step 2 -->
                    <div v-show="currentStep == 2">
                        <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                            <div class="onboarding-slide" ng-class="{'hide' : currentIndex != 2 }" style="">
                                <div class="onboarding-side-by-side">
                                    <div class="headerimg">
                                        <img class="img" alt="" src="/assets/img/img_step0.png">
                                    </div>
                                    <div class="onboarding-content with-gradient">
                                        <p class="title">
                                            Bank &amp; Super Details
                                        </p>
                                        <div class="onboarding-text">
                                            Retirement is more wonderful with super, help your employees transfering super whenever they recieve their wages
                                        </div>
                                        <form name="bankForm" class="ng-pristine ng-valid">
                                            <h5>Payment Method</h5>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <select @change="onChange($event)" class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" name="state">
                                                            <option value="Bank">Bank</option>
                                                            <option value="Cash" selected="selected">Cash</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row fade ng-scope" v-if="paymentMethod === 'Bank'" style="">
                                                <div class="col-sm-5">
                                                    <div class="form-group">
                                                        <label for="">Account Name <span class="text-danger">*</span></label>
                                                        <input v-model="bankAccountName" class="form-control error ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-maxlength ng-Account-Name" placeholder="" type="text" name="accountName" maxlength="32">
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label for="">BSB <span class="text-danger">*</span></label>
                                                        <input v-model="bankAccountBSB" class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern ng-valid-maxlength ng-BSB" name="bsb" type="text" ng-pattern="^[0-9]{6}$" maxlength="6">
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <label for="">Acc. Number <span class="text-danger">*</span></label>
                                                        <input v-model="bankAccountNumber" class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern ng-valid-maxlength ng-Acc-Number" name="accountNumber" type="text" ng-pattern="^[0-9-]{1,9}$" maxlength="9">
                                                        <small>6-9 Digits</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                            <h5>Super Fund</h5>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Type<span class="text-danger">*</span></label>
                                                        <div class="form-group">
                                                            <select @change="onChangeType($event)" class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" name="state" ng-model="employeeDetails_new.su.ty">
                                                                <option value="Regulated Superannuation Fund" selected="selected">Regulated Superannuation Fund</option>
                                                                <option value="Self Managed Superannuation Fund">Self Managed Superannuation Fund</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Contribution Rate (%)<span class="text-danger">*</span></label>
                                                        <input v-model.number="contributionRate" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-Contribution-Rate" placeholder="9.5" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="superFundType === 'Self Managed Superannuation Fund'" class="ng-scope">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">Name <span class="text-danger">*</span></label>
                                                            <input v-model="fundName" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-Sf-Name" type="text" name="self-managed-fund-name">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">ABN <span class="text-danger">*</span></label>
                                                            <input v-model="fundABN" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ng-Sf-ABN" type="text" name="self-managed-fund-abn" maxlength="11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">Electronic Service Address</label>
                                                            <input v-model="fundAddress" class="form-control ng-pristine ng-untouched ng-valid ng-empty" type="text" name="self-managed-fund-electronic-service-address" ng-model="employeeDetails_new.su.sm.esa">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">Employer Number (optional)</label>
                                                            <input v-model="fundEmployerNumber" class="form-control ng-pristine ng-untouched ng-valid ng-empty" type="text" name="self-managed-fund-employer-number" ng-model="employeeDetails_new.su.sm.en">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h6>Bank details</h6>
                                                    </div>
                                                    <div class="col-sm-5">
                                                        <div class="form-group">
                                                            <label for="">Account Name <span class="text-danger">*</span></label>
                                                            <input v-model="fundBankName" class="form-control error ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-maxlength ng-Sf-Account-Name" placeholder="" type="text" name="selfSuperAccountName" required="" maxlength="32">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <div class="form-group">
                                                            <label for="">BSB <span class="text-danger">*</span></label>
                                                            <input v-model="fundBankBSB" class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-maxlength ng-Sf-BSB" name="selfSuperBsb" type="text" required="" maxlength="6">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="">Account Number <span class="text-danger">*</span> <small>6~9 digits</small></label>
                                                            <input v-model="fundBankNumber" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength ng-Sf-AccountNumber" name="selfSuperAccountNumber" type="text" ng-pattern="^[0-9-]{1,9}$" maxlength="9">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="superFundType === 'Regulated Superannuation Fund'" class="ng-scope">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label for="">Search Super Company by Name, or ABN</label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <input v-model="edtSearchSupperFund" class="form-control ng-pristine ng-untouched ng-valid ng-empty" type="text" name="search-superfund">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <button v-on:click="searchSupperFund()" class="btn btn-primary" type="button" style="margin-top:3px" ng-disabled="superSearching">
                                                                <span class="spinner-grow spinner-grow-sm ng-hide"></span>
                                                                Search
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="alert alert-green ng-hide" role="alert" ng-show="superList &amp;&amp; superList.length>0">
                                                    <h6 style="margin-bottom:10px">Select your super fund</h6>
                                                    <table class="table table-sm table-border-white">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">ABN</th>
                                                                <th scope="col">USI</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div v-if="isShowListSupperFund && listSupperFund.length > 0" class="alert alert-green" role="alert" ng-show="superList &amp;&amp; superList.length>0" style="">
                                                    <h6 style="margin-bottom:10px">Select your super fund</h6>
                                                    <table class="table table-sm table-border-white">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">ABN</th>
                                                                <th scope="col">USI</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(item) in listSupperFund" :key="item.id" @click="detailSupperFund(item.identifierValue)" ng-repeat="superFundDetails in superList" class="text-primary-hover pointer ng-scope" style="">
                                                                <td class="ng-binding">{{item.organisationName}}</td>
                                                                <td class="ng-binding">{{item.identifierValue}}</td>
                                                                <td class="ng-binding">{{item.fundProductUsi}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <el-pagination class="pagination" @current-change="handleCurrentChange" v-show="isShowListSupperFund && totalPage > 1" background layout="prev, pager, next" :page-size=pageSize :total=totalPage>
                                                </el-pagination>
                                                <table v-show="isShowListSupperFund && listSupperFund.length == 0" style="width:100%" class="fade ng-scope text-danger">
                                                    <tbody>
                                                        <tr>
                                                            <td><b>Sorry! We cannot find this Super Company.</b></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div v-show="isShowDetailSupperFund" class="row">
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">Super Fund Name</label>
                                                            <div class="small-label text-danger text-right pointer" style="margin-top: -26px;" @click="remoteSupperFund()">
                                                                <i class="icon-feather-trash-2"></i>
                                                            </div>
                                                            <div style="margin-top:10px" class="">
                                                                <b class="ng-binding"></b>
                                                                <b class="ng-binding" style="margin-top: 20px;">{{organisationName}}</b>
                                                            </div>
                                                            <div style="margin-top:10px" class="">
                                                                <b class="ng-binding"></b>
                                                                <div class="ng-binding">ABN : {{identifierValue}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">Number</label>
                                                            <input v-model="memberNumber" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-number" name="superfund-number" placeholder="Member Number" type="text">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="padding:12px">
                                <div class="col-6 text-left">
                                    <button type="button" class="btn btn-link" v-on:click="backStepResgitry()" style="">
                                        <i class="icon-feather-arrow-left"></i> Previous
                                    </button>
                                </div>
                                <div class="col-6 text-right">
                                    <button class="btn btn-primary ng-binding" v-on:click="openModalStep3()" ng-disabled="loading">
                                        <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span>
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- Loading -->
                        <div v-if="isLoading">
                            <Loading></Loading>
                        </div>
                    </div>
                    <!-- Step 3-->
                    <div v-show="currentStep == 3" class="fade">
                        <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                            <div class="onboarding-slide" ng-class="{'hide' : currentIndex != 3 }" style="">
                                <div class="onboarding-side-by-side" style="align-items: inherit">
                                    <div class="headerimg">
                                        <img class="img" alt="" src="/assets/img/img_step0.png">
                                    </div>
                                    <div class="onboarding-content with-gradient">
                                        <p class="title">
                                            Tax Details
                                        </p>
                                        <div class="onboarding-text">
                                            <!--    Tax doesn’t have to be tricky! Enter your employee’s tax details here. -->
                                        </div>
                                        <form class="ng-pristine ng-valid ng-valid-maxlength">
                                            <div class="row">
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <label for="">Date of Birth</label>
                                                        <div class="form-row align-items-center">
                                                            <div class="col-auto" style="width:70px">
                                                                <input v-model="edtDay" class="form-control text-center ng-Day" placeholder="DD" min="1" max="31" maxlength="2">
                                                            </div>
                                                            <div class="col-auto" style="width:70px">
                                                                <input v-model="edtMonth" class="form-control text-center ng-Month" min="1" max="12" placeholder="MM" maxlength="2">
                                                            </div>
                                                            <div class="col-auto" style="width:100px">
                                                                <input v-model="edtYear" class="form-control text-center ng-Year" min="1900" max="2018" maxlength="4" placeholder="YYYY">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="payRate">Status of Residency</label>
                                                        <select v-model="statusResidency" @change="statusResidencyChange($event)" class="form-control ng-pristine ng-untouched ng-valid ng-not-empty ng-Status-Residency" name="state">
                                                            <option value="AustraliaResident" selected="selected">Australia Resident</option>
                                                            <option value="ForeignResident">Foreign Resident</option>
                                                            <option value="WorkingHoliday">Working Holiday</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group" style="margin-bottom:2px">
                                                        <label for="payRate" class="ng-binding">Tax File Number</label>
                                                        <div class="input-group">
                                                            <input v-model="taxNumber" v-show="!isExemption" type="number" class="form-control ng-tax-file-number" id="payRate" maxlength="9">
                                                            <select v-model="tfnExemption" v-show="isExemption" @change="onTypeExemptionChange($event)" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="state" ng-model="employeeDetails_new.tx.txe" style="height: 37px;" ng-class="{ 'hide' : !employeeDetails_new.tx.txe }">
                                                                <option value="NoTFNQuoted">No TFN Quoted</option>
                                                                <option value="TFNPending">TFN Pending</option>
                                                                <option value="Under18">Under 18</option>
                                                                <option value="Pensioner">Pensioner</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="small-label pointer text-primary" v-show="!isExemption" v-on:click="clickTaxFileNumber()">No tax file number?</div>
                                                    <div class="small-label pointer text-primary" v-show="isExemption" v-on:click="clickTaxFileNumber()">Has TFN?</div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <h6>Additional Information</h6>
                                                    <div class="form-check" style="margin: 5px 0;">
                                                        <input v-model="claimTaxFreeThreshold" class="form-check-input ng-pristine ng-untouched ng-valid ng-not-empty" type="checkbox" id="claimTaxFreeThreshold">
                                                        <label class="form-check-label" for="tx-o_ft">
                                                            Claim tax free threshold
                                                        </label>
                                                    </div>
                                                    <div class="form-check" style="margin: 5px 0;">
                                                        <!-- <input v-model="hasHecsOrHelpDebt" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" id="hasHecsOrHelpDebt">
                                                        <label class="form-check-label" for="tx-o_hp">
                                                            Has HELP or TSL debt
                                                        </label> -->
                                                        <input
                                                            v-model="hasHecsOrHelpDebt"
                                                            name="hasHecsOrHelpDebt"
                                                            class="form-check-input ng-pristine ng-untouched ng-valid ng-empty"
                                                            type="checkbox"
                                                            id="hasHecsOrHelpDebt"
                                                        />
                                                        <label class="form-check-label" for="hasHecsOrHelpDebt">
                                                            <!-- Has HECS or HELP debt -->
                                                            Has study and training support loan debt 
                                                            <i class="fa fa-info-circle" title="Any of the following-
                                                            Higher Education Loan Program (HELP) debt
                                                            VET Student Loan (VSL) debt
                                                            Financial Supplement (FS) debt
                                                            Student Start-up Loan (SSL) debt (including ABSTUDY SSL debts)
                                                            Trade Support Loan (TSL) debt"></i>
                                                        </label>
                                                    </div>
                                                    <!-- <div class="form-check" style="margin: 5px 0;">
                                                        <input v-model="hasTslOrStudentStart" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" id="hasTslOrStudentStart">
                                                        <label class="form-check-label" for="tx-o_sl">
                                                            Has Student Start-up Loan
                                                        </label>
                                                    </div> -->
                                                    <!-- <div class="form-check" style="margin: 5px 0;">
                                                            <input v-model="hasFinancialSupplementDebt" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" id="tx-o_fs" >
                                                            <label class="form-check-label" for="tx-o_fs">
                                                                Has Financial Supplement debt
                                                            </label>
                                                        </div> -->
                                                </div>
                                            </div>
                                            <!-- <hr>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h6>Tax File Number Declaration</h6>
                                                        <div class="form-check" style="margin: 5px 0;">
                                                            <input v-model="taxFileNumberDeclaration" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="tfnd-p" ng-model="employeeDetails_new.tfnd.p">
                                                            <label class="form-check-label" for="tfnd-p">
                                                                Tick this box if you would like to lodge TFN Declaration in the next STP lodgement. By ticking
                                                                this, you also declare that the information you have given is true and correct.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            <div class="row" style="margin-top: 10px;">
                                                <div class="col-12">
                                                    <div @click="checkOverwriteTax()" class="row text-left" style="margin-top: 15px;">
                                                        <v-app class="text-left" style="margin-left: 20px;">
                                                            <v-switch id="overwriteTax" v-model="hasTaxTemplate" inset style="margin-top: 0px;"></v-switch>
                                                        </v-app>
                                                        <div class="text-right" style="margin-top: 3px;">
                                                            <label for="overwriteTax">Overwrite tax</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="input-group col-6" style="margin-top: 5px;">
                                                    <input v-model.number="taxTemplate" v-show="hasTaxTemplate" type="number" class="form-control ng-tax-file-number" id="payRate" maxlength="9">
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- </slick> -->
                            <div class="row" style="padding:12px">
                                <div class="col-6 text-left">
                                    <button type="button" class="btn btn-link" v-on:click="backStepResgitry()" style="">
                                        <i class="icon-feather-arrow-left"></i> Previous
                                    </button>
                                </div>
                                <div class="col-6 text-right">
                                    <!-- ngIf: currentIndex == 0 -->
                                    <button class="btn btn-primary ng-binding" v-on:click="activeEmployee()" ng-disabled="loading">
                                        <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span>
                                        Active employee
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import * as External from "@/utils/External.js";
import * as Countries from "@/utils/Countries"
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import CountryFlag from 'vue-country-flag'
import * as Utils from "@/utils/Utils";

import moment from 'moment'

import {
    CHECK_CONTACT_NUMBER,
    CHECK_TAX_NUMBER,
    SEARCH_SUPERFUND,
    DETAIL_SUPERFUND,
    UPDATE_EMPLOYEE_BY_EMAIL,
    CHECK_CODE_ENCODE
} from "@/store/actionsType";
import * as Logs from '@/utils/Logs'
import * as Validate from "@/utils/Validate.js";
export default {
    name: "Register",
    components: {
        VueGoogleAutocomplete,
        CountryFlag
    },
    mounted() {
        External.head_link("/assets/css/tt/styleRegister.css");
        External.head_link("/assets/css/tt/animate.min.css");
        External.head_link("/assets/css/tt/style(1)Register.css");
        // Logs.json("===>", this.$route.query)
        // Logs.stringKey('check code');
    },
    destroyed() {
        External.del_link("/assets/css/tt/styleRegister.css");
        External.del_link("/assets/css/tt/animate.min.css");
        External.del_link("/assets/css/tt/style(1)Register.css");
    },
    data() {
        return {
            currentStep: 1,
            countries: [],
            // Step 1
            address: "",
            isCheckFindMyAddress: false,
            edtAddress1: "",
            edtAddress2: "",
            edtSuburb: "",
            edtPostcode: "",
            edtState: "",
            countryCode: "",
            country: "",
            edtcontactNumber: "",
            isValidate: true,

            // Step 2
            isLoading: false,
            paymentMethod: "Cash",
            bankAccountName: "",
            bankAccountBSB: "",
            bankAccountNumber: "",
            superFundType: "Regulated Superannuation Fund",
            contributionRate: 9.5,
            fundName: "",
            fundABN: "",
            fundAddress: "",
            fundEmployerNumber: "",
            fundBankName: "",
            fundBankBSB: "",
            fundBankNumber: "",
            memberNumber: "",
            // Supper Fund
            isShowListSupperFund: false,
            edtSearchSupperFund: "",
            listSupperFund: [],
            listSupperFundFull: [],
            isShowDetailSupperFund: false,
            organisationName: "",
            identifierValue: "",
            totalPage: 0,
            pageSize: 10,
            currenPage: 1,

            // Step 3
            isExemption: false,
            tfnExemption: "NoTFNQuoted",
            edtDay: "",
            edtMonth: "",
            edtYear: "",
            statusResidency: "AustraliaResident",
            taxNumber: "",
            claimTaxFreeThreshold: true,
            hasHecsOrHelpDebt: false,
            //hasTslOrStudentStart: false,
            hasFinancialSupplementDebt: false,
            taxFileNumberDeclaration: false,
            hasTaxTemplate: false,
            taxTemplate: 0,
        };
    },
    created: function () {
        this.checkCodeEncode();
    },
    methods: {
        querySearch(queryString, cb) {
            var listDrop = this.countries;
            queryString = this.country
            var results = queryString ? listDrop.filter(this.createFilter(queryString)) : listDrop;
            cb(results);
        },
        clickAutocomplete() {
            this.country = ""
        },
        getBackAutocompete() {
            var timeout = setTimeout(() => {
                if (this.country == "") {
                    this.country = Countries.getCountry(this.countryCode)
                }
                clearTimeout(timeout)
            }, 300);
        },
        createFilter(queryString) {
            return (country) => {
                return (country.text.toLowerCase().indexOf(queryString.toLowerCase()) != -1);
            };
        },
        changeLanguage(item) {
            this.country = item.text
            this.countryCode = item.value
            if (this.countryCode != "au") {
                this.edtState = "OTHER"
                window.$('.ng-state')[0].disabled = true
            } else {
                window.$('.ng-state')[0].disabled = false
            }
            Logs.string('country', this.countryCode)
        },
        checkCodeEncode(){
            this.$root.$refs.AppCyrus.start()
            Logs.string('code', this.$route.query.code);
            this.$store.dispatch(CHECK_CODE_ENCODE, this.$route.query.code)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                //    Logs.json('data', response);
                    if (!response.result) {
                        this.$router.push({
                            path: "/link-expired"
                        });
                    } else {
                        this.countries = []
                        var keys = Object.keys(Countries.getList());
                        for (var i = 0; i < keys.length; i++) {
                            this.countries.push({
                                "text": Countries.getCountry(keys[i]),
                                "value": keys[i].toLowerCase()
                            })
                        }
                    }
                }).catch(() => {
                    this.$root.$refs.AppCyrus.finish()
                })
        },
        closeModal() {
            this.$modal.hide('modal-code-expired');
            this.gotoLogin();
        },
        getAddressData(addressData) {
            var street_number = ""
            if (addressData.street_number != undefined) {
                street_number = addressData.street_number + " "
            }
            this.address = street_number + addressData.route + " " + addressData.locality + " " + addressData.administrative_area_level_1
            this.edtAddress1 = street_number + addressData.route;
            this.edtSuburb = addressData.locality;
            this.edtPostcode = addressData.postal_code;
            this.countryCode = "au"
            this.country = Countries.getCountry("AU")
            window.$('.ng-state')[0].disabled = false
            this.edtState = addressData.administrative_area_level_1;
        },
        getAddress2Data(addressData) {
            this.edtAddress2 = addressData.street_number + " " + addressData.route + " " + addressData.locality;
        },
        backStepResgitry() {
            this.changeStepRegistry(this.currentStep - 1)
        },
        changeStepRegistry(index) {
            this.currentStep = index;
            window.console.log('currentStep=' + this.currentStep);
        },

        onChange(event) {
            if (this.currentStep == 1) {
                this.edtState = event.target.value
            } else {
                this.paymentMethod = event.target.value
            }
        },
        onChangeType(event) {
            this.superFundType = event.target.value
        },
        statusResidencyChange(event) {
            this.statusResidency = event.target.value
            if (this.hasTaxTemplate) {
                this.disabled()
            } else {
                this.checkStatusResidency(this.statusResidency)
            }
        },
        checkStatusResidency(statusResidency) {
            if (statusResidency == 'AustraliaResident') {
                document.getElementById("claimTaxFreeThreshold").disabled = false;
                document.getElementById("hasHecsOrHelpDebt").disabled = false;
                // document.getElementById("hasTslOrStudentStart").disabled = false;
            } else if (statusResidency == 'ForeignResident') {
                document.getElementById("claimTaxFreeThreshold").checked = false;
                this.claimTaxFreeThreshold = false

                document.getElementById("claimTaxFreeThreshold").disabled = true;
                document.getElementById("hasHecsOrHelpDebt").disabled = false;
                // document.getElementById("hasTslOrStudentStart").disabled = false;
            } else if (statusResidency == 'WorkingHoliday') {
                document.getElementById("claimTaxFreeThreshold").checked = false;
                document.getElementById("hasHecsOrHelpDebt").checked = false;
                // document.getElementById("hasTslOrStudentStart").checked = false;
                this.claimTaxFreeThreshold = false
                this.hasHecsOrHelpDebt = false
                // this.hasTslOrStudentStart = false

                document.getElementById("claimTaxFreeThreshold").disabled = true;
                document.getElementById("hasHecsOrHelpDebt").disabled = true;
                // document.getElementById("hasTslOrStudentStart").disabled = true;
            }
        },
        checkOverwriteTax() {
            if (this.hasTaxTemplate) {
                this.disabled()
            } else {
                this.checkStatusResidency(this.statusResidency)
            }
        },
        disabled() {
            document.getElementById("claimTaxFreeThreshold").checked = false;
            document.getElementById("hasHecsOrHelpDebt").checked = false;
            // document.getElementById("hasTslOrStudentStart").checked = false;
            this.claimTaxFreeThreshold = false
            this.hasHecsOrHelpDebt = false
            // this.hasTslOrStudentStart = false

            document.getElementById("claimTaxFreeThreshold").disabled = true;
            document.getElementById("hasHecsOrHelpDebt").disabled = true;
            // document.getElementById("hasTslOrStudentStart").disabled = true;
        },
        onTypeExemptionChange(event) {
            this.tfnExemption = event.target.value
        },
        clickTaxFileNumber() {
            if (this.isExemption) {
                this.isExemption = false
                this.tfnExemption = null
            } else {
                this.isExemption = true
                this.tfnExemption = "NoTFNQuoted"
                this.taxNumber = ""
            }
        },
        openModalStep2() {
            this.checkValidateStep1()
            if (this.isValidate) {
                console.log('SHDBFDSBFSDJ');
                //this.checkContactNumber()
                this.changeStepRegistry(2)
            }
        },
        openModalStep3() {
            this.checkValidateStep2()
            if (this.isValidate) {
                this.changeStepRegistry(3)
            }
        },
        checkValidateStep1() {
            this.isValidate = true
            window.$('.is-invalid').removeClass('is-invalid')
            var address = window.$(".ng-address")
            var address1 = window.$(".ng-address1")
            var suburb = window.$(".ng-suburb")
            var postcode = window.$(".ng-postcode")
            var stale = window.$(".ng-state")
            var phone = window.$(".ng-phone")
            if (this.isCheckFindMyAddress) {
                //Check Address 1
                if (Validate.isEmpty(this.edtAddress1)) {
                    address1.addClass("is-invalid");
                    this.isValidate = false
                }
                // Check Suburb
                if (Validate.isEmpty(this.edtSuburb)) {
                    suburb.addClass("is-invalid");
                    this.isValidate = false
                }
                // Check PostCode
                if (Validate.isEmpty(this.edtPostcode)) {
                    postcode.addClass("is-invalid");
                    this.isValidate = false
                }
                // Check Stale
                if (Validate.isEmpty(this.edtState)) {
                    stale.addClass("is-invalid");
                    this.isValidate = false
                }
            } else {
                if (Validate.isEmpty(this.address)) {
                    address.addClass("is-invalid");
                    this.isValidate = false;
                }
            }
            if (!Validate.isPhone(this.edtcontactNumber.replace(/[\s()]+/g, ''))) {
                phone.addClass("is-invalid");
                this.isValidate = false
            }
        },
        checkValidateStep2() {
            this.isValidate = true
            var accountName = window.$(".ng-Account-Name")
            var bSB = window.$(".ng-BSB")
            var accNumber = window.$(".ng-Acc-Number")
            var contributionRate = window.$(".ng-Contribution-Rate")
            var SfName = window.$(".ng-Sf-Name")
            var SfABN = window.$(".ng-Sf-ABN")
            var SfAccountName = window.$(".ng-Sf-Account-Name")
            var SfBSB = window.$(".ng-Sf-BSB")
            var SfAccountNumber = window.$(".ng-Sf-AccountNumber")

            if (this.paymentMethod == 'Bank') {
                //Check Account Name
                if (Validate.isEmpty(this.bankAccountName)) {
                    accountName.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    accountName.removeClass("is-invalid");
                }
                // Check BSB
                if (Validate.isEmpty(this.bankAccountBSB) || Validate.isNumber(this.bankAccountBSB)) {
                    bSB.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    bSB.removeClass("is-invalid");
                }
                // Check ACC Number
                if (Validate.isEmpty(this.bankAccountNumber) || Validate.isNumber(this.bankAccountNumber)) {
                    accNumber.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    accNumber.removeClass("is-invalid");
                }
            }
            // Contribution Rate
            if (Validate.isEmpty(this.contributionRate)) {
                contributionRate.addClass("is-invalid");
                this.isValidate = false
            } else {
                contributionRate.removeClass("is-invalid");
            }
            if (this.superFundType === "Self Managed Superannuation Fund") {
                //Check ng-Sf-Name
                if (Validate.isEmpty(this.fundName)) {
                    SfName.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    SfName.removeClass("is-invalid");
                }
                // Check ng-Sf-ABN
                if (Validate.isEmpty(this.fundABN) || Validate.isNumber(this.fundABN)) {
                    SfABN.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    SfABN.removeClass("is-invalid");
                }
                // Check ng-Sf-Account-Name
                if (Validate.isEmpty(this.fundBankName)) {
                    SfAccountName.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    SfAccountName.removeClass("is-invalid");
                }
                // Check ng-Sf-BSB
                if (Validate.isEmpty(this.fundBankBSB) || Validate.isNumber(this.fundBankBSB)) {
                    SfBSB.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    SfBSB.removeClass("is-invalid");
                }
                // Check ng-Sf-AccountNumber
                if (Validate.isEmpty(this.fundBankNumber) || Validate.isNumber(this.fundBankNumber)) {
                    SfAccountNumber.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    SfAccountNumber.removeClass("is-invalid");
                }
            } else if (this.isShowDetailSupperFund) {
                var number = window.$('.ng-number')
                if (Validate.isEmpty(this.memberNumber)) {
                    number.addClass("is-invalid")
                    this.isValidate = false
                } else {
                    number.removeClass('.ng-number')
                }
            }
        },
        checkValidateStep3() {
            var day = window.$(".ng-Day")
            var month = window.$(".ng-Month")
            var year = window.$(".ng-Year")
            var tax = window.$(".ng-tax-file-number")
            var status = window.$(".ng-Status-Residency")
            //Check Day
            if (Validate.isValidateDate("Day", this.edtDay)) {
                day.addClass("is-invalid");
            } else {
                day.removeClass("is-invalid");
            }
            // Check Month
            if (Validate.isValidateDate("Month", this.edtMonth)) {
                month.addClass("is-invalid");
            } else {
                month.removeClass("is-invalid");
            }
            // Check Year
            if (Validate.isValidateDate("Year", this.edtYear)) {
                year.addClass("is-invalid");
            } else {
                year.removeClass("is-invalid");
            }

            var checkTax = false
            var checkTaxEmpty = false
            if (!this.isExemption) {
                checkTax = Validate.CheckTFN(this.taxNumber)
                checkTaxEmpty = Validate.isEmpty(this.taxNumber)
            } else {
                checkTax = false;
                checkTaxEmpty = false;
            }

            //Check Tax File Number
            if (checkTaxEmpty || checkTax) {
                tax.addClass("is-invalid");
            } else {
                tax.removeClass("is-invalid");
            }
            //Check StatusResidency
            if (Validate.isEmpty(this.statusResidency)) {
                status.addClass("is-invalid");
            } else {
                status.removeClass("is-invalid");
            }
            // 
            if (Validate.isValidateDate("Day", this.edtDay) || (Validate.isValidateDate("Month", this.edtMonth)) || Validate.isValidateDate("Year", this.edtYear) || Validate.isEmpty(this.statusResidency) || checkTax || checkTaxEmpty) {
                this.isValidate = false
            } else {
                this.isValidate = true
            }
        },
        gotoLogin() {
            this.$router.push({
                path: "/activeemployeedone"
            });
        },

        // Request API
        handleCurrentChange(page) {
            this.currenPage = page
            this.listSupperFund = []
            var from = (this.currenPage - 1) * this.pageSize + 1
            var to = 0
            if (this.pageSize * this.currenPage <= this.totalPage) {
                to = this.pageSize * this.currenPage
            } else {
                to = this.totalPage
            }
            for (var i = from; i < to; i++) {
                this.listSupperFund.push(this.listSupperFundFull[i])
            }
        },
        searchSupperFund() {
            this.$root.$refs.AppCyrus.start()
            var request = {
                "keySearch": this.edtSearchSupperFund,
                "page": "",
                "lenght": ""
            }
            this.$store.dispatch(SEARCH_SUPERFUND, request)
                .then((response) => {
                 //   Logs.json('SEARCH_SUPERFUND ===> Data Response: ', response)
                    this.$root.$refs.AppCyrus.finish()
                    this.isShowListSupperFund = true
                    this.listSupperFundFull = response.listSuperFund
                    if (response.listSuperFund != undefined) {
                        this.totalPage = response.listSuperFund.length
                    }
                    for (var i = 0; i < this.pageSize; i++) {
                        this.listSupperFund.push(this.listSupperFundFull[i])
                    }
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        detailSupperFund(keyABN) {
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(DETAIL_SUPERFUND, keyABN)
                .then((response) => {
                 //   Logs.json('DETAIL_SUPERFUND ===> Data Response: ', response)
                    this.$root.$refs.AppCyrus.finish()
                    this.isShowListSupperFund = false
                    this.isShowDetailSupperFund = true

                    this.organisationName = response.organisationName
                    this.identifierValue = response.identifierValue

                    this.fundName = response.organisationName
                    this.fundABN = response.identifierValue
                    this.fundAddress = response.addressLine1
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        remoteSupperFund() {
            this.isShowDetailSupperFund = false
            this.organisationName = ""
            this.identifierValue = ""
            this.memberNumber = ""
            this.fundName = ""
            this.fundABN = ""
            this.fundAddress = ""
            this.edtSearchSupperFund = ""
        },
        checkContactNumber() {
            this.$root.$refs.AppCyrus.start()
            var modelRequest = {
                "companyId": this.$route.query.companyid,
                "employeeId": 0,
                "contactNumber": this.edtcontactNumber.replace(/[\s()]+/g, ''),
            }
            this.$store.dispatch(CHECK_CONTACT_NUMBER, modelRequest)
                .then(() => {
                    this.$root.$refs.AppCyrus.finish()
                    this.changeStepRegistry(2)
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        checkTaxNumber() {
            this.$root.$refs.AppCyrus.start()
            var modelRequest = {
                "companyId": this.$route.query.companyid,
                "employeeId": 0,
                "taxNumber": this.taxNumber,
            }
            this.$store.dispatch(CHECK_TAX_NUMBER, modelRequest)
                .then(() => {
                    this.$root.$refs.AppCyrus.finish()
                    this.updateEmployee()
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },

        activeEmployee() {
            this.checkValidateStep3()
            if (this.isValidate) {
                if (this.taxNumber != '') {
                    this.checkTaxNumber()
                } else {
                    this.updateEmployee()
                }
            }
        },
        updateEmployee() {
            var dataRequest = {
                'id': Number(this.$route.query.employeeid),
                "companyId": Number(this.$route.query.companyid),
                //step1
                "address1": this.edtAddress1,
                "address2": this.edtAddress2,
                "suburb": this.edtSuburb,
                "postcode": this.edtPostcode,
                "state": this.edtState,
                "country": this.countryCode,
                "contactNumber": this.edtcontactNumber.replace(/[\s()]+/g, ''),
                //step2
                "bank": {
                    "paymentMethod": this.paymentMethod,
                    "bankAccountName": this.bankAccountName,
                    "bankAccountBSB": this.bankAccountBSB,
                    "bankAccountNumber": this.bankAccountNumber
                },
                "fund": {
                    "superFundType": this.superFundType,
                    "contributionRate": this.contributionRate,
                    "fundName": this.fundName,
                    "fundABN": this.fundABN,
                    "fundAddress": this.fundAddress,
                    "fundUSI": "",
                    "fundEmployerNumber": this.fundEmployerNumber,
                    "fundBankName": this.fundBankName,
                    "fundBankBSB": this.fundBankBSB,
                    "fundBankNumber": this.fundBankNumber,
                    "memberNumber": this.memberNumber
                },
                // step3
                "birthDate": this.edtYear + "-" + this.edtMonth + "-" + this.edtDay,
                "statusResidency": this.statusResidency,
                "claimTaxFreeThreshold": this.claimTaxFreeThreshold,
                "hasHecsOrHelpDebt": this.hasHecsOrHelpDebt,
                // "hasTslOrStudentStart": this.hasTslOrStudentStart,
                "hasFinancialSupplementDebt": this.hasFinancialSupplementDebt,
                "taxFileNumberDeclaration": this.taxFileNumberDeclaration,
                "isExemption": this.isExemption,
                "tfnExemption": this.tfnExemption,
                "taxNumber": this.taxNumber,
                "taxTemplate": this.taxTemplate,
                "hasTaxTemplate": this.hasTaxTemplate,
                'annualLeaveBalance': 0,
                'sickLeaveBalance': 0,
                "grossEarningsBalance": 0,
                "taxBalance": 0,
                "superGuaranteeBalance": 0,
                "employerContributionBalance": 0,
                "employerContribution": 0,
                "superSalarySacrifice": 0,
                "passwordHash": "",
                "asAtBalance": new Date(),
                'terminationDate': null
            }
            var code = this.$route.query.code;
            console.log("Data request Update: " + JSON.stringify(dataRequest))
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(UPDATE_EMPLOYEE_BY_EMAIL, {
                code,
                dataRequest
            }).then(() => {
                this.$root.$refs.AppCyrus.finish()
                Utils.toastSuccess("Active employee successful!")
                this.gotoLogin()
            }).catch(() => {
                this.$root.$refs.AppCyrus.fail()
            });
        },
        getDate(inputDate) {
            var date = new Date(inputDate);
            var day = date.getDate()
            var mount = date.getMonth() + 1
            var year = date.getFullYear()
            if (day.toString().length == 1) {
                day = "0" + day
            }
            if (mount.toString().length == 1) {
                mount = "0" + mount
            }
            console.log("Date " + day + "-" + mount + "-" + year)
            return year + "-" + mount + "-" + day
        },
        formatDate(date) {
            return moment(date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
        },
        mouseOver() {
            this.isActiveSearch = true
        },
        formatPhoneNumber() {
            var x = this.edtcontactNumber.replace(/[^+\d]/g, '').match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
            if (x[1].indexOf('+') == 0) {
                this.edtcontactNumber = !x[2] ? '(' + x[1].slice(0, 3) + ') ' + x[1].slice(3) : '(' + x[1].slice(0, 3) + ') ' + x[1].slice(3) + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            } else {
                this.edtcontactNumber = !x[2] ? x[1] : '' + x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            }
        }
    },
    watch: {
        "edtcontactNumber": function (val) {
            var x = val.replace(/[^+\d]/g, '').match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
            if (x[1].indexOf('+') == 0) {
                this.edtcontactNumber = !x[2] ? '(' + x[1].slice(0, 3) + ') ' + x[1].slice(3) : '(' + x[1].slice(0, 3) + ') ' + x[1].slice(3) + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            } else {
                this.edtcontactNumber = !x[2] ? x[1] : '' + x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import '/assets/css/company.css';
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");

.pagination {
    justify-content: center;
    text-align: center;
}
</style>
